@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  scroll-behavior: smooth !important;
}

/* It makes the HTML progress bar filling smooth when value change. */
progress::-webkit-progress-value {
  transition: 0.6s width ease-out;
}

@layer base {
  .btn-gradient {
    @apply !bg-gradient !bg-[length:300%_300%] hover:saturate-[1.2] shadow duration-100 !border-0 !border-transparent !bg-transparent animate-shimmer disabled:!bg-none disabled:!bg-gray-500/30  !text-white;
  }
  .btn {
    @apply !capitalize;
  }
}

.tilt-card {
  transform: perspective(1000px) rotateX(2deg) rotateY(-5deg) scale3d(1, 1, 1) !important;
  transform-style: preserve-3d;
  transition: all 0.6s;
}

.tilt-card:hover {
  transform: rotateY(0deg) !important;
}

.flame {
  position: relative;
  display: inline-block;
}

.flame::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 5px; /* Adjust as needed */
  background: linear-gradient(orange, red, yellow); /* Flame colors */
  border-radius: 50%;
}

.shrink-button:hover {
  transform: scale3d(0.95, 0.95, 1.01);
  transform-style: preserve-3d;
}

.border-debug {
  border: 1px solid red;
}
